import { ContentPage } from 'designsystem/components/5-templates/ContentPage';
import { SingleContentSectionFeature } from 'features/Modules/ContentSection/SingleContentSectionFeature';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import { TopBannerFeature } from 'features/Modules/TopBanner';

export const CampaignPageFeature: React.FC<Content.CampaignPage> = ({ properties, children, additionalTop, additionalPageContent }) => {
	const { pageHeader, pageSection, pageSections, bannerContent } = properties ?? {};
	const hasLanguagePicker = additionalTop?.bottom || !!additionalPageContent;

	return (
		<ContentPage
			additionalTop={additionalTop}
			additionalPageContent={additionalPageContent}
			topBanner={bannerContent?.length > 0 && <TopBannerFeature bannerContent={bannerContent} />}
			header={
				pageHeader?.length > 0 &&
				pageHeader.map((headerModule, index) => (
					<ModuleFeature
						key={index}
						contentModule={headerModule}
						additionalProps={hasLanguagePicker ? { noTopMargin: true } : undefined}
						className={hasLanguagePicker ? 'u-top-margin-important--none' : index === 0 ? 'u-top-margin-important--lg' : ''}
					/>
				))
			}
		>
			{children}
			<SingleContentSectionFeature pageSection={pageSection} />
			{pageSections?.length > 0 && pageSections.map((sectionModule, index) => <ModuleFeature key={index} contentModule={sectionModule} />)}
		</ContentPage>
	);
};
